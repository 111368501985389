import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {


  private productoUrl = environment.productoURL

  constructor(private httpClient: HttpClient) { }

  //Obtener los ultimos diez productos
  public getAllTenProductos(): Observable<any> {
    return this.httpClient.get<any>(this.productoUrl + 'lasted');
  }

  //Obtener los productos especiales
  public getAllEspecialesProductos(): Observable<any> {
    return this.httpClient.get<any>(this.productoUrl + 'especiales/lista');
  }

  //Solicitud listar productos por seccion
  public getProductosSeccion(seccionId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.productoUrl}search?seccionId=${seccionId}`)
  }

  //Solicitud obtener un producto
  public getOneProducto(prod_id: number): Observable<any> {
    return this.httpClient.get<any>(this.productoUrl + prod_id);
  }

  //Solicitud Productos por categoria
  public getProductosCategoria(categoriaId: number): Observable<any> {
    return this.httpClient.get<any>(`${this.productoUrl}search?categoriaId=${categoriaId}`)
  }

  //Cambios en el carrito
  private cartSubject = new BehaviorSubject<any[]>(this.getCartProducts()); // Inicializa el BehaviorSubject con los productos en el carrito
  cartItems = this.cartSubject.asObservable(); // Observable para los productos en el carrito


  // Añadir producto al carrito
  public addToCart(product: any, quantity: number = 1, selectedSize?: string): boolean {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];

    // Verificar si el producto ya está en el carrito
    const itemIndex = cart.findIndex((item: any) => item.prod_id === product.prod_id);

    if (itemIndex === -1) {
      //Agregar selectedSize si hay talla seleccionada
      if (selectedSize) {
        // Si no está en el carrito, añadirlo con la cantidad especificada (por defecto será 1)
        cart.push({ ...product, quantity, selectedSize });
        localStorage.setItem('cart', JSON.stringify(cart));
        this.cartSubject.next(cart); // Emitir cambios
        return true;
      } else {
        // Si no está en el carrito, añadirlo con la cantidad especificada (por defecto será 1)
        cart.push({ ...product, quantity });
        localStorage.setItem('cart', JSON.stringify(cart));
        this.cartSubject.next(cart); // Emitir cambios
        return true;
      }
    }

    return false; // No se añade porque ya está en el carrito
  }

  // Limpiar el carrito
  public clearCart(): void {
    localStorage.removeItem('cart'); // Elimina el carrito del localStorage
    this.cartSubject.next([]); // Emite un carrito vacío para actualizar cualquier suscripción
  }


  // Obtener productos en el carrito
  public getCartProducts(): any[] {
    return JSON.parse(localStorage.getItem('cart')) || [];
  }

  // Eliminar producto del carrito
  public removeFromCart(product: any): void {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    cart = cart.filter((item: any) => item.prod_id !== product.prod_id);
    localStorage.setItem('cart', JSON.stringify(cart));
    this.cartSubject.next(cart); // Emite los cambios
  }


  // Actualizar cantidad de un producto en el carrito
  public incrementCartQuantity(product: any, selectedTalla?: string): void {
    // Obtener el carrito del localStorage
    let cart = JSON.parse(localStorage.getItem('cart')) || [];

    // Encontrar el índice del producto en el carrito
    const itemIndex = cart.findIndex((item: any) => item.prod_id === product.prod_id);

    if (itemIndex > -1) {
      const cartItem = cart[itemIndex];

      cartItem.selectedSize = selectedTalla;

      // Verificar si el producto tiene inventario con talla
      if (cartItem.inventario && cartItem.inventario.length > 1 && selectedTalla) {
        // Si hay tallas y se seleccionó una talla específica
        const invIndex = cartItem.inventario.findIndex((inv: any) => inv.inv_talla === selectedTalla);

        if (invIndex > -1) {
          const inventarioItem = cartItem.inventario[invIndex];

          // Si la cantidad actual del producto es mayor que el inventario disponible para esa talla
          if (cartItem.quantity > inventarioItem.inv_cantidad_disponible) {
            cartItem.quantity = inventarioItem.inv_cantidad_disponible;
            console.log(`Cantidad ajustada a ${cartItem.quantity} debido a la falta de inventario para la talla ${selectedTalla}.`);
          }

          // Verificar si hay stock disponible para esa talla
          if (inventarioItem.inv_cantidad_disponible > 0 && cartItem.quantity < inventarioItem.inv_cantidad_disponible) {
            // Incrementar la cantidad específica de la talla
            if (!cartItem.tallas) {
              cartItem.tallas = {};
            }

            // Llevar un registro separado de la cantidad por talla
            cartItem.tallas[selectedTalla] = (cartItem.tallas[selectedTalla] || 0) + 1;

            // Incrementar la cantidad total del producto
            cartItem.quantity += 1;

          } else {
            console.log("No hay más stock disponible para la talla seleccionada.");
          }
        }
      } else {
        // No hay talla, por lo tanto, considerar solo el primer inventario (un solo elemento)
        const inventarioItem = cartItem.inventario[0];

        // Ajustar quantity si es mayor que el inventario disponible
        if (cartItem.quantity > inventarioItem.inv_cantidad_disponible) {
          cartItem.quantity = inventarioItem.inv_cantidad_disponible;
          console.log(`Cantidad ajustada a ${cartItem.quantity} debido a la falta de inventario.`);
        }

        if (inventarioItem.inv_cantidad_disponible > 0 && cartItem.quantity < inventarioItem.inv_cantidad_disponible) {
          // Incrementar la cantidad en el carrito
          cartItem.quantity += 1;

        } else {
          console.log("No hay más stock disponible.");
        }
      }

      // Actualizar el carrito en el localStorage
      localStorage.setItem('cart', JSON.stringify(cart));
      // Emitir los cambios en el carrito
      this.cartSubject.next(cart);
    }
  }


  // Disminuir cantidad de un producto en el carrito
  decrementCartQuantity(product: any, selectedTalla?: string): void {
    // Obtener el carrito del localStorage
    let cart = JSON.parse(localStorage.getItem('cart')) || [];

    // Encontrar el índice del producto en el carrito
    const itemIndex = cart.findIndex((item: any) => item.prod_id === product.prod_id);

    if (itemIndex > -1) {
      const cartItem = cart[itemIndex];
      cartItem.selectedSize = selectedTalla; // Mantener la talla seleccionada

      // Verificar si el producto tiene inventario con talla
      if (cartItem.inventario && cartItem.inventario.length > 1 && selectedTalla) {
        // Si hay tallas y se seleccionó una talla específica
        const invIndex = cartItem.inventario.findIndex((inv: any) => inv.inv_talla === selectedTalla);

        if (invIndex > -1) {
          const inventarioItem = cartItem.inventario[invIndex];

          // Verificar si hay una cantidad mayor a 0 para esa talla
          if (cartItem.tallas && cartItem.tallas[selectedTalla] > 0) {
            // Disminuir la cantidad específica de la talla
            cartItem.tallas[selectedTalla] -= 1;

            // Disminuir la cantidad total del producto
            cartItem.quantity -= 1;

          } else {
            console.log(`No puedes disminuir más la cantidad para la talla ${selectedTalla}.`);
          }

          // Si la cantidad total del producto llega a 0, puedes considerar eliminarlo del carrito si lo deseas
          if (cartItem.quantity === 0) {
            console.log(`El producto ${product.prod_nombre} fue eliminado del carrito.`);
            cart.splice(itemIndex, 1); // Elimina el producto del carrito
          }

        } else {
          console.log(`No se encontró inventario para la talla seleccionada ${selectedTalla}.`);
        }
      } else {
        // No hay talla seleccionada o el producto no tiene variantes de talla
        const inventarioItem = cartItem.inventario[0];

        if (cartItem.quantity > 0) {
          // Disminuir la cantidad en el carrito
          cartItem.quantity -= 1;

        } else {
          console.log("No puedes disminuir más la cantidad.");
        }

        // Si la cantidad total del producto llega a 0, puedes considerar eliminarlo del carrito si lo deseas
        if (cartItem.quantity === 0) {
          console.log(`El producto ${product.prod_nombre} fue eliminado del carrito.`);
          cart.splice(itemIndex, 1); // Elimina el producto del carrito
        }
      }

      // Actualizar el carrito en el localStorage
      localStorage.setItem('cart', JSON.stringify(cart));
      // Emitir los cambios en el carrito
      this.cartSubject.next(cart);
    }
  }



  //Metodo para verificar la talla seleccionada y ver si la talla seleccionada tiene disponibilidad de lo contrario ajusta cantidad a l disponible
  public verificarSizeTalla(product: any, selectedTalla: string) {
    // Obtener el carrito del localStorage
    let cart = JSON.parse(localStorage.getItem('cart')) || [];

    // Encontrar el índice del producto en el carrito
    const itemIndex = cart.findIndex((item: any) => item.prod_id === product.prod_id);

    if (itemIndex > -1) {
      const cartItem = cart[itemIndex];
      cartItem.selectedSize = selectedTalla; // Mantener la talla seleccionada

      // Verificar si el producto tiene inventario con talla
      if (cartItem.inventario && cartItem.inventario.length > 1 && selectedTalla) {
        // Si hay tallas y se seleccionó una talla específica
        const invIndex = cartItem.inventario.findIndex((inv: any) => inv.inv_talla === selectedTalla);

        if (invIndex > -1) {
          const inventarioItem = cartItem.inventario[invIndex];

          // Si la cantidad actual del producto es mayor que el inventario disponible para esa talla
          if (cartItem.quantity > inventarioItem.inv_cantidad_disponible) {
            cartItem.quantity = inventarioItem.inv_cantidad_disponible;
            console.log(`Cantidad ajustada a ${cartItem.quantity} debido a la falta de inventario para la talla ${selectedTalla}.`);
            // Igualar a la cantidad disponible
            cartItem.tallas[selectedTalla] = inventarioItem.inv_cantidad_disponible;
          }
        }
      }
      // Actualizar el carrito en el localStorage
      localStorage.setItem('cart', JSON.stringify(cart));
      // Emitir los cambios en el carrito
      this.cartSubject.next(cart);
    }
  }


  // Obtener el monto total del carrito
  public getCartTotalAmount(): number {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    return cart.reduce((acc: number, item: any) => {
      const itemPrice = item.prod_precio;

      // Calcular el monto basado en la cantidad en el carrito (no en el stock disponible)
      return acc + item.quantity * itemPrice;
    }, 0);
  }



  // Obtener la cantidad total de elementos en el carrito
  public getCartTotalItems(): number {
    let cart = this.getCartProducts();

    return cart.reduce((acc: number, item: any) => {
      // Si el producto tiene múltiples inventarios (por ejemplo, ropa con tallas)
      if (item.inventario && item.inventario.length > 1) {
        return acc + item.inventario.reduce((invAcc: number, invItem: any) => {
          return invAcc + invItem.inv_cantidad_disponible;
        }, 0);
      } else {
        // Producto sin variantes
        return acc + item.inventario[0].inv_cantidad_disponible;
      }
    }, 0);
  }


  // Método para verificar disponibilidad de inventario
  public checkInventoryAvailability(product: any, quantity?: number, selectedSize?: string): boolean {
    const inventory = product.inventario; // Obtén el inventario asociado al producto

    if (!inventory) {
      return false;
    }

    // Verificar la disponibilidad de cantidad para la talla seleccionada
    const availableInventory = inventory.find((inv: any) => inv.inv_talla === selectedSize);
    if (availableInventory && availableInventory.inv_cantidad_disponible >= quantity) {
      return true;
    }

    return false;
  }

}
