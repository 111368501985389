<!--footer section -->
<footer [class]="class">
	<div class="dark-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<!-- <div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>CONÓCELO TODO PRIMERO!</h4>
								<p>No te pierdas nada de Artesanias Lumar suscribiéndote a nuestro sitio web.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form"
							action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda"
							target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control"
									placeholder="Ingresa tu correo">
							</div>
							<button type="submit" class="btn btn-solid">suscríbete</button>
						</form>
					</div>
				</div> -->
			</section>
		</div>
	</div>
	<section class="section-b-space dark-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p class="desc_lumar">En nuestra empresa ofrecemos una variedad de artículos artesanales para
							oficina, decorativos para el hogar, accesorios y prendas de vestir etc.</p>
						<div class="footer-social">
							<ul>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-google-plus"
											aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
								</li>
								<li>
									<a href="javascript:void(0)"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col offset-xl-1">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Categorias</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li><a href="/shop/categoria/joyeria">Joyeria</a></li>
								<li><a href="/shop/categoria/regalos">Regalos</a></li>
								<li><a href="/shop/categoria/ropa-hombre">Ropa para hombre</a></li>
								<li><a href="/shop/categoria/ropa-mujer">Ropa para mujer</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>¿Por qué elegirnos?</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li><a href="javascript:void(0)">envío y devolución</a></li>
								<li><a href="javascript:void(0)">compras seguras</a></li>
								<li><a href="/pages/contact">contactos</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>Información</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<li><i class="fa fa-map-marker"></i>Calle 9 Nº 6-35 Centro
									Mocoa - Putumayo diagonal al tobogán</li>
								<li><i class="fa fa-phone"></i>Llámanos: +573124363780 +5784204605</li>
								<li><i class="fa fa-envelope-o"></i>Email: <a>artesaniaslumar&#64;yahoo.com</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} ARTESANIAS LUMAR</p>
					</div>
				</div>
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a><img src="assets/images/icon/visa.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/mastercard.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/paypal.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/american-express.png" alt=""></a>
							</li>
							<li>
								<a><img src="assets/images/icon/discover.png" alt=""></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->