import { Component, OnInit, Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, of, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { environment } from 'src/environments/environment';
import { ProductoService } from '../../services/productos/producto.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  urlApi = environment.apiBaseUrl

  public products: any[] = [];
  totalItems$: Observable<number>;
  public search: boolean = false;

  private cartSubscription: Subscription;



  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productoServices: ProductoService,
    private router: Router,) {
  }

  ngOnInit(): void {
    // Suscribirse a los cambios en el carrito
    this.cartSubscription = this.productoServices.cartItems.subscribe(cartItems => {
      this.products = cartItems;
    });
  }

  ngOnDestroy(): void {
    // Cancelar la suscripción al destruir el componente para evitar fugas de memoria
    if (this.cartSubscription) {
      this.cartSubscription.unsubscribe();
    }
  }

  searchToggle() {
    this.search = !this.search;
  }


  public get getTotal(): Observable<number> {
    this.totalItems$ = of(this.productoServices.getCartTotalAmount());
    return this.totalItems$
  }

  public removeItem(product: any) {
    this.productoServices.removeFromCart(product);
    //Volver a Cargar los productos desde el localStorage
    this.products = this.productoServices.getCartProducts();
  }

  //Enviar id del producto a detallar
  sendProducto(prod_id: number) {
    localStorage.setItem('prod_id', prod_id.toString());
    this.router.navigate(['/shop/product/three/column/trim-dress']);
  }


  
}
