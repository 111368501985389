import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductoService } from 'src/app/shared/services/productos/producto.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  urlApi = environment.apiBaseUrl

  @Input() title: string = 'Nuevos Productos'; // Default
  @Input() type: string = 'fashion'; // Default Fashion

  public product_list: any[] = [];

  public segmentedProducts: any[][] = []; // Array para almacenar segmentos de productos


  public ImageSrc: string

  // Configuración del slider
  NewProductSliderConfig = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: true
  };

  constructor(
    public productoService: ProductoService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.listarDiezProductos();
  }

  //Obtener los ultimos diez productos
  listarDiezProductos() {
    this.productoService.getAllTenProductos().subscribe(
      (data: any) => {
        this.product_list = data.map((product: any) => {

          return {
            ...product,
            img_url: product.imagen_producto && product.imagen_producto.length > 0 ? product.imagen_producto[0].imagen_url : 'path/to/default/image.jpg'
          };
        });
        this.segmentProducts();
      },
      error => {
        console.error('Error al obtener productos', error);
      }
    );
  }


  //Enviar id del producto a detallar
  sendProducto(prod_id: number) {
    localStorage.setItem('prod_id', prod_id.toString());
    this.router.navigate(['/shop/product/three/column/trim-dress']);
  }

  // Método para dividir el array en segmentos de 3
  segmentProducts() {
    const size = 3; // Tamaño del segmento
    this.segmentedProducts = [];
    for (let i = 0; i < this.product_list.length; i += size) {
      this.segmentedProducts.push(this.product_list.slice(i, i + size));
    }
  }
}
