<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-5  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
                            class="img-fluid blur-up lazyload bg-img" alt="Product Image">
                    </div>
                </div>
                <div class="col-lg-7 rtl-text">
                    <div class="product-right">
                        <h2>{{ product.prod_nombre | titlecase }}</h2>
                        <h3>{{ product.prod_precio | currency }} <del>{{ product.prod_precio_oferta | currency }}</del>
                        </h3>
                        <div class="border-product">
                            <h6 class="product-title">detalles del producto</h6>
                            <p>{{ product.prod_descripcion}}</p>
                        </div>
                        <div class="product-description border-product">
                            <h5 class="avalibility" *ngIf="counter <= getStockDisponible()"><span>En Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > getStockDisponible()"><span>Agotado</span></h5>
                            <h6 class="product-title">Cantidad</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus"
                                            (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"
                                        [value]="counter" disabled />
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus"
                                            (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)" class="btn btn-solid"
                                [class.disabled]="counter > product.stock" (click)="addToCart(product, counter)">añadir a
                                carrito</a>
                            <a (click)="sendProducto(product.prod_id)" class="btn btn-solid">ver detalles</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<app-cart-modal #cartModal [product]="product"></app-cart-modal>