<div class="product-container">
    <div class="img-wrapper">
        <div class="lable-block">
            <span class="lable3">Nuevo</span>
        </div>
        <div class="front">
            <a (click)="sendProducto(product.prod_id)">
                <img [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
                    class="img-fluid blur-up lazyload bg-img" alt="Product Image">
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a (click)="sendProducto(product.prod_id)">
                <img [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
                    class="img-fluid blur-up lazyload bg-img" alt="Product Image">
            </a>
        </div>

        <div class="cart-box">
            <button title="Añadir al carrito" (click)="CartModal.openModal(product)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </button>

            <a href="javascript:void(0)" title="Comprar Producto" (click)="buyNow(product)">
                <i class="ti-money" aria-hidden="true"></i>
            </a>
            
            <a href="javascript:void(0)" title="Detalle del Producto" (click)="QuickView.openModal()">
                <i class="ti-more" aria-hidden="true"></i>
            </a>
        </div>
    </div>
    <div class="product-detail text-center ">
        <div class="rating">
            <bar-rating [rate]="5" [readOnly]="true" class="text-center pro-rating"></bar-rating>
        </div>
        <a (click)="sendProducto(product.prod_id)">
            <h6>{{ product.prod_nombre | titlecase }}</h6>
        </a>
        <h4>{{ product.prod_precio | currency }} <del>{{ product.prod_precio_oferta | currency }}</del></h4>
    </div>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" ></app-cart-modal>