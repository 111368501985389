<div class="img-wrapper">
  <div class="img-block">
    <div class="lable-wrapper">
      <span class="lable1" *ngIf="product.new">new</span>
      <span class="lable2" *ngIf="product.sale">{{ product?.discount }}% off</span>
    </div>
    <div class="front">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" class="img-fluid lazy-loading"
          alt="{{ product.images[0].alt }}">
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading"
          alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <div class="cart-info">
      <button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </button>
      <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </button>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>
<div class="product-detail">
  <div>
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
      <h6>{{ product?.title | titlecase }}</h6>
    </a>
    <h4>
      {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
      <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price |
          currency:currency?.currency:'symbol' }}</span></del>
    </h4>
    <ul class="color-variant" *ngIf="Color(product?.variants).length">
      <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
        (click)="ChangeVariants(color, product)">
      </li>
    </ul>
  </div>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>