import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ProductoService } from 'src/app/shared/services/productos/producto.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  urlApi = environment.apiBaseUrl

  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() especial: boolean = false // Default False

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string

  constructor(
    private router: Router,
    private productService: ProductService,
    private productoService: ProductoService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }



  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }


  // Buy Now
  async buyNow(product: any, quantity?: number, selectedSize?: string) {
    // Verifica si el producto tiene tallas disponibles
    const hasSizes = product.inventario && product.inventario.some(inv => inv.inv_talla && inv.inv_talla.trim() !== '');

    // Si el producto tiene tallas disponibles, verifica que se haya seleccionado una talla
    if (hasSizes && !selectedSize) {
      this.toastrService.warning('Por favor, debes seleccionar una talla antes de continuar con la compra.', 'Talla Requerida', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      return;
    }

    // Obtener la información completa del producto
    const productDetails = await this.productoService.getOneProducto(product.prod_id).toPromise();

    // Verificar la disponibilidad de inventario
    const inventario = productDetails.inventario;
    // Comprobar si hay suficiente inventario disponible para la talla seleccionada
    const isAvailable = inventario.some((inv: any) => inv.inv_cantidad_disponible >= 1);

    if (!isAvailable) {
      this.toastrService.warning('No hay suficiente inventario para comprar el producto seleccionado.', 'Inventario Insuficiente', {
        timeOut: 4000,
        positionClass: 'toast-top-center',
      });
      return;
    }

    // Añadir el producto al carrito con la talla seleccionada (si corresponde)
    const status = await this.productoService.addToCart(product, quantity, selectedSize);

    // Redirigir a la página de checkout si el producto se añade al carrito correctamente
    if (status) {
      this.router.navigate(['/shop/checkout']);
    } else {
      this.toastrService.warning('No se pudo añadir el producto al carrito. Intentalo más tarde', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }
  }



  //Enviar id del producto a detallar
  sendProducto(prod_id: number) {
    localStorage.setItem('prod_id', prod_id.toString());
    this.router.navigate(['/shop/product/three/column/trim-dress']);
  }
}
