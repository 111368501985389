import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { ProductoService } from 'src/app/shared/services/productos/producto.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss']
})
export class CartModalComponent implements OnInit, AfterViewInit, OnDestroy {

  urlApi = environment.apiBaseUrl

  @Input() product: any;
  @Input() currency: any;

  @ViewChild("cartModal", { static: false }) CartModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public products: any[] = [];

  //Array para el inventario
  producto_inventario: any[] = [];

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private productService: ProductService,
    private productoServices: ProductoService,
    private toastrService: ToastrService,) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }
  
  async openModal(product: any, quantity?: number, selectedSize?: string) {
    // Realizar la lógica para filtrar productos relacionados
    await this.productoServices.getProductosCategoria(product.categoriaId).subscribe(response => {
      this.products = response.filter(items => items.prod_id !== product.prod_id);
    });

    // Obtener la información completa del producto
    this.productoServices.getOneProducto(product.prod_id).subscribe(
      (data: any) => {
        // Verificar la disponibilidad de inventario
        const inventario = data.inventario;
        // Comprobar si hay inventario disponible
        const isAvailable = inventario.some((inv: any) => inv.inv_cantidad_disponible >= 1);

        if (isAvailable) {
          // Añadir el producto al carrito
          const status = this.productoServices.addToCart(product, quantity, selectedSize);

          if (status) {
            this.modalOpen = true;
            if (isPlatformBrowser(this.platformId)) {  // Para la renderización del lado del servidor
              this.modalService.open(this.CartModal, {
                size: 'lg',
                ariaLabelledBy: 'Cart-Modal',
                centered: true,
                windowClass: 'theme-modal cart-modal CartModal'
              }).result.then((result) => {
                this.closeResult = `Result ${result}`;
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
            }
          } else {
            this.toastrService.warning('El producto ya se encuentra en el carrito.', 'Aviso', {
              timeOut: 3000,
              positionClass: 'toast-top-center',
            });
          }
        } else {
          this.toastrService.warning('No hay suficiente inventario para el producto seleccionado.', 'Aviso', {
            timeOut: 3000,
            positionClass: 'toast-top-center',
          });
        }
      },
      err => {
        console.log(err.error.message);
      }
    );
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }


  public continueShopping() {
    this.modalService.dismissAll();
  }

}
