<div class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o *ngIf="segmentedProducts.length > 0" [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container *ngFor="let segment of segmentedProducts">
      <ng-template carouselSlide>
        <div>
          <div class="d-flex align-items-center" *ngFor="let product of segment">
            <a (click)="sendProducto(product.prod_id)">
              <img class="img-fluid"
                [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
                alt="Producto">
            </a>
            <div class="media-body align-self-center">
              <a (click)="sendProducto(product.prod_id)">
                <h6>{{ product.prod_nombre | titlecase }}</h6>
              </a>
              <h4>{{ product.prod_precio | currency }} <del>{{ product.prod_precio_oferta | currency }}</del></h4>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>

</div>